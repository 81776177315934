.Manager {
  margin-left: 8px;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

th {
  display: table-cell;
  vertical-align: bottom;
  text-align: center;
}

table {
  border-spacing: 0;
}

.usercell {
  padding: 0;
  padding-right: 4px;
  border-right: 1px solid lightgray;
  width: 300px;
}

.cell {
  cursor: arrow;
  user-select: none;
  border-right: 1px solid lightgray;
  border-bottom: 1px dashed #eee;
  padding: 0;
  text-align: center;
}

.userpop {
  max-width: 400px;
}

div.group {
  font-size: 12pt;
  cursor: pointer;
  background-color: #eee;
}

#alltables {
  flex-grow: 1;
  overflow: scroll;
}
